<template>
  <div>
      <div :class="otherClass" v-if="other" v-html="otherContent"></div>
      <h1 :class="titleClass" v-if="title" v-html="titleContent"></h1>
      <p :class="'mt-4 ' + textClass" v-if="text" v-html="textContent"></p>
      <button v-if="button" :class="'btn btn-danger text-center ' + buttonClass" v-html="buttonContent"></button>
      <router-link v-if="link" :to="linkHref" :class="'btn btn-danger text-center ' + linkClass"> {{linkContent}} </router-link>
  </div>
</template>

<script>
export default {
  props: {
    other: Boolean,
    title: Boolean,
    text: Boolean,
    button: Boolean,
    link: Boolean,
    otherContent: String,
    titleContent: String,
    textContent: String,
    buttonContent: String,
    otherClass: String,
    titleClass: String,
    textClass: String,
    buttonClass: String,
    linkClass: String,
    linkContent: String,
    linkHref: String
  }
}
</script>

<style scoped lang="scss">
.first-container {
    p{
      margin: 0;
  }
}
.btn {
  margin-top: 18px;
  padding: 12px 42px;
  border-radius: 60px;
  font-size: 12px;
}
</style>

<template>
  <div class="mentionslegales">

    <div class="container bg-secondary py-5">
        <div class="p-lg-5 m-lg-auto">
          <TitleTextButton
            :other="true"
            :text="true"
            otherContent='<p class="subtitle component-other text-center">Mentions Légales</p>'
            textContent='Le présent site est la propriété de la société AZ Initiatys, SARL au capital de 7500 € dont le siège social est situé au
                        <a href="https://www.google.fr/maps/place/AZ+INITIATYS/@48.8515513,2.2164417,17z/data=!3m1!4b1!4m5!3m4!1s0x47e67b31b1bda6f3:0x84a52bef725057c0!8m2!3d48.8515513!4d2.2186304"
                        target="_blank">7 rue des Gâte Ceps, 92210 SAINT-CLOUD</a>'
            otherClass="text-dark"
          />
        </div>

        <div class="container pt-5 px-0 px-lg-5 pt-lg-0 m-lg-auto">
          <p class='m-0'>L'utilisateur de ce site reconnaît avoir pris connaissance des mentions légales et s'engage à les respecter.</p>
          <p class='mentions'>Tous les éléments composant ce site sont la propriété exclusive de la société AZ Initiatys et/ou des éléments sur lesquels la société AZ Initiatys dispose de droits, notamment d'exploitation, permettant leur diffusion et leur communication. AZ Initiatys est une marque déposée. Out Development, Priméval, Carré des Initiatives sont des marques déposées par la société AZ Initiatys. Toute utilisation de ces marques sans l'autorisation expresse de la société AZ Initiatys est formellement interdite au sens des articles L.713-2 et suivants du Code de la propriété intellectuelle. Tous les noms de produits ou de sociétés mentionnés dans le présent site et n'appartenant pas à AZ Initiatys sont des marques déposées par leurs titulaires respectifs. Les informations de ce site sont fournies par la société AZ Initiatys à titre indicatif. La société AZ Initiatys ne saurait garantir l'exactitude des informations diffusées sur son site. En conséquence, l'utilisateur reconnaît utiliser ces informations sous sa seule responsabilité. Les liens hypertextes de ce site en direction d'autres ressources présentes sur Internet ne sauraient engager la responsabilité de la société AZ Initiatys.</p>
        </div>

        <div class="container pt-5 px-0 p-lg-5 pt-lg-0 m-lg-auto">
          <div class="row flex-column flex-lg-row">
            <div class="col-lg-6">
              <div class="info">
                <div class="director">
                  <p class="text-danger">Gérant : <br><a href="https://www.linkedin.com/in/hdechene/">Hervé Dechene</a></p>
                </div>
                <div class="register">
                  <p class="text-danger">Enregistrée au :  <br><a href="http://www.greffe-tc-nanterre.fr/index.php?pg=rcs_index">RCS NANTERRE 448 061 754 - NAF : 7022Z</a></p>
                </div>
                <div class="director">
                  <p class="text-danger">Directeur de la publication : <br><a href="https://www.linkedin.com/in/hdechene/">Hervé Dechene</a></p>
                </div>
              </div>
          </div>

          <div class="col-lg-6">
              <div class="conceptor">
                <p class="text-danger">Ce site a été conçu par : <br><a href="https://komlo.fr">la société KOMLO</a></p>
              </div>
              <div class="conceptor">
                <p class="text-danger">Développé par : <br><a href="https://wildbelle.eu">WildBelle Studio</a></p>
              </div>
              <div class="web">
                <p class="text-danger m-0">Il est hébergé par : <br><a href="https://www.ovh.com/fr/">la société OVH</a></p>
              </div>

          </div>
        </div>

    </div>
    </div>

  </div>
</template>

<script>
import TitleTextButton from '../components/TitleTextButton'

export default {
  components: {
    TitleTextButton
  }
}
</script>
